type DescriptionProps = {
  text?: string;
  width?: string;
  margin?: string;
  font?: string;
  fontSize?: string;
  mobileWidth?: string;
  align?: string;
  maxWidth?: string;
  fontWeight?: string;
  color?: string;
  marginMobile?: string;
  alignMobile?: string;
  maxWidthMobile?: string;
  justifyDesktop?: string;
  justifyMobile?: string;
  fontMobile?: string;
  order?: string;
  children?: object;
  lineHeight?: number;
};

const Description = ({
  text,
  width,
  margin,
  font,
  fontSize,
  mobileWidth,
  align,
  maxWidth,
  fontWeight,
  color,
  marginMobile,
  alignMobile,
  maxWidthMobile,
  justifyDesktop,
  justifyMobile,
  fontMobile,
  order,
  children,
  lineHeight
}: DescriptionProps) => {
  return (
    <div className="description-container">
      {/* toDo : refactor all description component to have child only */}
      {text ? (
        <p className="description-text" dangerouslySetInnerHTML={{ __html: text }}></p>
      ) : (
        <p className="description-text">{children}</p>
      )}
      <style jsx>{`
        .description-container {
          display: flex;
          justify-content: ${justifyDesktop ? justifyDesktop : "center"};
        }
        .description-text {
          width: ${width};
          color: ${color ? color : "var(--color-gray)"};
          max-width: ${maxWidth};
          text-align: ${align ? align : "center"};
          font-family: ${font};
          font-size: ${fontSize};
          margin: ${margin};
          font-weight: ${fontWeight ? fontWeight : "normal"};
          line-height: ${lineHeight ? lineHeight : ""};
        }
        .link {
          color: red;
        }
        @media only screen and (max-width: 600px) {
          .description-container {
            justify-content: ${justifyMobile ? justifyMobile : ""};
          }
          .description-text {
            text-align: ${alignMobile};
            font-size: ${fontMobile ? fontMobile : "12px"};
            margin: ${marginMobile};
            width: ${mobileWidth};
            max-width: ${maxWidthMobile};
          }
        }
        @media only screen and (max-width: 768px) {
          .description-container {
            order: ${order};
          }
        }
      `}</style>
    </div>
  );
};

export default Description;
